var _a;
export const env = {
    // GraphQL
    /** Provides the url to the livingapi graphql service */
    graphqlEndpoint: process.env.VUE_APP_GRAPHQL_ENDPOINT,
    googleApiKey: process.env.VUE_APP_GOOGLE_API_KEY,
    livingapps: `https://${(_a = process.env.VUE_APP_INGRESS_HOST) !== null && _a !== void 0 ? _a : 'my.living-apps.de'}`,
    // OIDC
    /** Provides the oidc client id for the pwa */
    clientId: process.env.VUE_APP_OIDC_CLIENT_ID,
    /** Provides the oidc discover endpoint to discover all required oidc endpoints via oidcfrog*/
    discoverEndpoint: process.env.VUE_APP_OIDC_DISCOVERY_ENDPOINT,
    /** redirect the user to this url after a successfull login */
    loginRedirect: process.env.VUE_APP_ODIC_REDIRECT_LOGIN_URL,
    /** redirect the user to this url after a successfull logout */
    logoutRedirect: process.env.VUE_APP_OIDC_REDIRECT_LOGOUT_URL,
    // additional
    /** The app version, can be a commit hash or a tag */
    version: process.env.VUE_APP_VERSION || 'local',
    /** equivalent to NODE_ENV === 'production */
    isProduction: process.env.NODE_ENV === 'production',
    /** enables features which are only for development */
    isNightlyRelease: process.env.VUE_APP_NIGHTLY_RELEASE === 'TRUE',
    /** disables the serviceworker for e2e testing */
    isTest: process.env.VUE_APP_TEST === 'TRUE',
    /** Tells if the target is an iOS Wrapper and not a PWA */
    iOsOnly: process.env.VUE_APP_IOS_ONLY === 'TRUE',
};
console.debug(env);
export default env;
